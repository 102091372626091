<template>
  <div>
    <v-container>
      <h1 style="margin-right: 20px">
        Complaints - CRM
      </h1>

      <router-link :to="{ name: 'CRM' }">Zurück</router-link>

      <div style="margin-top: 20px">
        <v-text-field
          v-model="searchStr"
          outlined
          label="Mail-ID oder E-Mail eingeben"
          append-icon="mdi-magnify"
          @keydown.enter="search"
          @click:append="search"
        ></v-text-field>
      </div>

      <!-- Results -->

      <!-- No Result -->
      <div v-if="noResult" style="text-align: center; padding: 20px 0">
        <v-icon size="100">$candle-sad</v-icon>
        <p class="mt-4">
          Es konnte kein Complaint mit deiner Eingabe gefunden werden.
        </p>
      </div>
      <div v-else>
        <unvergessen-complaint-view :complaint="complaint" :key="i" v-for="(complaint, i) in complaints" class="mb-4" @deactivate="complaint.active = false"></unvergessen-complaint-view>
      </div>
    </v-container>
  </div>
</template>

<script>
import ComplaintView from '@/components/crm/complaints/ComplaintView.vue'
import axios from 'axios'

export default {
  data () {
    return {
      searchStr: '',
      complaints: [],
      noResult: false
    }
  },
  methods: {
    search () {
      this.noResult = false

      axios.get('/crm/complaints/search', { params: { key: this.searchStr } })
        .then(res => {
          if (res.data.length <= 0) {
            this.noResult = true
            return
          }

          this.complaints = res.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  components: {
    unvergessenComplaintView: ComplaintView
  }
}
</script>

<style scoped>
.link {
  padding-top: 14px;
  margin-left: 20px;
  text-decoration: none;
  color: var(--v-text-base)
}

.selected {
  text-decoration: underline;
}
</style>
