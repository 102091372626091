<template>
  <div>
    <v-card class="unvergessen-shadow" style="width: 100%;">
      <v-container>
        <v-row>
          <v-col cols="2">
            <div style="display: table; height: 100%">
              <div style="display: table-cell; vertical-align: middle">
                {{ translate(complaint.type) }}
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div style="display: table; height: 100%">
              <div style="display: table-cell; vertical-align: middle">
                {{ complaint.mail }}
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div style="display: table; height: 100%">
              <div style="display: table-cell; vertical-align: middle">
                {{ complaint.template }}
              </div>
            </div>
          </v-col>
          <v-col cols="2">
            <div v-if="complaint.type === 'memorialPage'">
              <v-btn style="width: 100%; text-transform: none" @click="pushTo('https://unvergessen.de/Gedenkseite/' + complaint.linkID)">
                Zur Gedenkseite
              </v-btn>
            </div>
            <div v-if="complaint.type === 'memorialMail'">
              <v-btn style="width: 100%; text-transform: none" @click="$router.push({ name: 'CRMMailSingleUser', params: { uid: complaint.linkID } })">
                Zum Gedenk-Post CRM
              </v-btn>
            </div>
            <div v-if="complaint.type === 'user'">
              <v-btn style="width: 100%; text-transform: none" disabled>
                Zum Nutzer
              </v-btn>
            </div>
            <div v-if="complaint.type === 'candle'">
              <v-btn style="width: 100%; text-transform: none" :disabled="!complaint.active" :class="{ error: complaint.active }" @click="openDeactivateDialog">
                {{ complaint.active ? 'Deaktivieren' : 'Bereits Inaktiv'}}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- Dialogs -->

    <!-- Deactivate Candle Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <div style="text-align: center; padding: 20px">
          <v-alert type="error" v-model="errorDeactivate">
            Die Benachrichtigungen konnten nicht deaktiviert werden. Bitte versuche es erneut.<br>
            Error: {{ errMessage }}
          </v-alert>
          <div class="title mb-6">
            Möchtest du diese Mail Adresse wirklich aus dem Verteiler nehmen?
          </div>
          <div class="mb-6">
            Die Mail Adresse erhält ab sofort keine Updates mehr zu bereits bestehenden Kerzen
          </div>

          <v-row>
            <v-col>
              <v-btn style="text-transform: none; width: 100%" @click="closeDeactivateDialog">
                Abbrechen
              </v-btn>
            </v-col>
            <v-col>
              <v-btn style="text-transform: none; width: 100%" class="error" @click="deactivate">
                Deaktivieren
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <!-- Success Dialog -->
    <unvergessen-success-dialog :dialog="success" @close="success = false" buttonText="OK">
      Die Benachrichtigungen wurden erfolgreich deaktiviert.
    </unvergessen-success-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['complaint'],
  data () {
    return {
      dialog: false,
      success: false,
      errorDeactivate: false,
      errMessage: ''
    }
  },
  methods: {
    translate (input) {
      let ret = ''
      switch (input) {
        case 'candle':
          ret = 'Kerze'
          break
        case 'memorialMail':
          ret = 'Gedenk-Post'
          break
        case 'memorialPage':
          ret = 'Gedenkseite'
          break
        case 'user':
          ret = 'Nutzer'
          break
        case 'newsletter':
          ret = 'Newsletter'
          break
        default:
          ret = 'Kein Type'
      }
      return ret
    },
    pushTo (target) {
      window.open(target, '_blank')
    },
    openDeactivateDialog () {
      this.dialog = true
    },
    closeDeactivateDialog () {
      this.dialog = false
    },
    deactivate () {
      this.errorDeactivate = false

      axios.post('/crm/complaints/deactivate', this.complaint)
        .then(() => {
          this.success = true
          this.errorDeactivate = false
          this.errMessage = ''
          this.closeDeactivateDialog()
          this.$emit('deactivate')
        })
        .catch(err => {
          this.success = false
          this.errorDeactivate = true
          this.errorMessage = err
        })
    }
  }
}
</script>
