<template>
  <div>
    <v-container>
      <h1 style="margin-bottom: 60px">
        CRM
      </h1>

      <h2 class="mb-4">
        Nach einem Nutzer suchen:
      </h2>
      <v-text-field
        v-model="searchStr"
        outlined
        label="E-Mail-Adresse oder Page_ID eingeben"
        append-icon="mdi-magnify"
        @click:append="search"
      ></v-text-field>

      <!-- Buttons -->
      <v-row>
        <dashboard-btn target="CRMMailUserCollection" icon="postbox" cols="3" v-if="user.accessRoles.crm.memorialMail">Gedenk-Post</dashboard-btn>
        <dashboard-btn target="CRMPageUserCollection" icon="gsuser" cols="3" v-if="user.accessRoles.crm.memorialPage">Gedenkseiten (User)</dashboard-btn>
        <dashboard-btn target="CRM" icon="candle" cols="3" v-if="user.accessRoles.crm.candle">Kerzen</dashboard-btn>
        <dashboard-btn target="CRM" icon="news" cols="3" v-if="user.accessRoles.crm.newsletter">Newsletter</dashboard-btn>
        <dashboard-btn target="CRMComplaints" icon="candle-sad" cols="3" v-if="user.accessRoles.crm.complaints">Complaints</dashboard-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardBtn from '@/components/dashboard/DashboardBtn.vue'
export default {
  data () {
    return {
      searchStr: ''
    }
  },
  methods: {
    search () {
      // TODO
      // search for this.searchStr
    }
  },
  components: {
    dashboardBtn: DashboardBtn
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  }
}
</script>

<style scoped>
* >>> .v-responsive__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center
}

.dashboard-btn {
  width: 100%;
  border-radius: 10px !important;
  box-shadow: 0px 0px 15px #3f3f3f2e !important
}
</style>
